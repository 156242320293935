<template>
  <div>
    <resolve-ticket-sidebar />
    <div class="d-flex justify-content-between">
      <h1>Informações gerais</h1>
      <b-button 
        v-if="details && details.support_ticket_status_id == supportTicketStatus.PENDING"
        variant="primary"
        type="submit" 
        class="mb-3" 
        @click="resolveTicket"
      >
        Finalizar Ticket
      </b-button>
    </div>

    <div v-if="loading" class="d-flex justify-content-center">
      <b-spinner variant="primary" />
    </div>
    <template v-else-if="details">
      <div class="d-flex align-items-center justify-content-between flex-wrap" style="margin-right: 8rem;">
        <div class="d-flex align-items-center mt-2">
          <b-avatar variant="light-primary" rounded>
            <feather-icon icon="AlertCircleIcon" size="18" />
          </b-avatar>
          <div class="ml-1">
            <h5 class="mb-0">
              {{ details.support_ticket_status }}
            </h5>
            <small>Status</small>
          </div>
        </div>
        <div class="d-flex align-items-center mt-2">
          <b-avatar variant="light-info" rounded>
            <feather-icon icon="BookmarkIcon" size="18" />
          </b-avatar>
          <div class="ml-1">
            <h5 class="mb-0">
              {{ details.support_ticket_type}}
            </h5>
            <small>Tipo</small>
          </div>
        </div>
        <div class="d-flex align-items-center mt-2">
          <b-avatar variant="light-success" rounded>
            <feather-icon icon="CalendarIcon" size="18" />
          </b-avatar>
          <div class="ml-1">
            <h5 class="mb-0">
              {{ details.created_at | onlyDate}}
            </h5>
            <small>Data de criação</small>
          </div>
        </div>
      </div>
      <b-row class="mt-1">
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Consultor </small>
            <h5>
              {{ details.added_by_user }}
            </h5>
          </div>
        </b-col>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Comentário </small>
            <h5 v-if="details.comment">
              {{ details.comment }}
            </h5>
            <h5 v-else>Comentário não informado</h5>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Lido por </small>

            <h5 v-if="details.read_by_user">
                {{ details.read_by_user }}
            </h5>
            <h5 v-else>Não lido</h5>
          </div>
        </b-col>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Número da proposta </small>
            <h5>
              {{
                details.proposal_number || "Número da proposta não informado"
              }}
            </h5>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Finalizado por </small>
            <h5 v-if="details.finished_by_user">
                {{ details.finished_by_user }}
            </h5>
            <h5 v-else>Não finalizado</h5>
          </div>
        </b-col>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> PN especialista </small>
            <h5>
              {{ details.closer_consultant || "Especialista não informado" }}
            </h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Data do contrato </small>
            <h5>
              {{ details.contract_date | onlyDate }}
            </h5>
          </div>
        </b-col>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Solução financeira </small>
            <h5>
              {{ details.financial_solution }}
            </h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Produto </small>
            <h5>
              {{ details.product }}
            </h5>
          </div>
        </b-col>
        <b-col md="6" sm="12" class="mt-1">
          <div>
            <small class="text-muted"> Empresa </small>
            <h5>
              {{ details.partner_company }}
            </h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <template>
          <b-col md="6" sm="12" class="mt-1">
            <div>
              <small class="text-muted"> Gateway de pagamento </small>
              <h5>
                {{ details.payment_gateway || "Não informado" }}
              </h5>
            </div>
          </b-col>
          <b-col md="6" sm="12" class="mt-1">
            <div>
              <small class="text-muted"> Identificador do pagamento </small>
              <h5>
                {{ details.payment_gateway_reference_id || "Não informado" }}
              </h5>
            </div>
          </b-col>
        </template>
      </b-row>
    </template>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BAvatar,
  BSpinner,
  BButton,
  BPopover,
  BCardText,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import * as types from "@/modules/support_ticket/store/types";
import { useToast } from "vue-toastification/composition";
import { floatToMoney } from "@/helpers/converters";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as accountTypes from "@/modules/account/store/types";
import * as supportTicketStatus from "@/constants/support_ticket_status";
import ResolveTicketSidebar from "../components/ResolveTicketSidebar";

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BSpinner,
    BButton,
    BPopover,
    BCardText,
    BDropdown,
    BDropdownItem,
    ResolveTicketSidebar,
  },
  setup() {
    return { toast: useToast() };
  },
  data() {
    return {
      supportTicketStatus,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      details: types.DETAILS_GENERAL_INFORMATION,
      user: accountTypes.USER,
    }),
  },
  mounted() {
    this.get();
  },
  methods: {
    ...mapActions({
      getDetailsGeneralInformation: types.GET_DETAILS_GENERAL_INFORMATION,
      openResolveTicketSidebar: types.OPEN_RESOLVE_TICKET_SIDEBAR
    }),
    resolveTicket() {
      this.openResolveTicketSidebar({
        id: this.$route.params.id,
        saveAction: this.get,
      });
    },
    get(page) {
      this.loading = true;
      this.getDetailsGeneralInformation(this.$route.params.id)
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao obter as informações gerais do contrato. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.$router.push({ name: "contracts-list" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
